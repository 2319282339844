import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Img from "gatsby-image"

export const RestaurantPostTemplate = ({
  content,
  contentComponent,
  description,
  featuredImage,
  comments,
  tags,
  references,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content
  let featuredImgFluid = featuredImage.childImageSharp.fluid
  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">

        <div className="columns">
          <div className="column is-10 is-offset-1">
              <div style={{paddingBottom: "20px"}}><a href="/restaurants/">Restaurants</a><a href="# " style={{color: "black"}}> > {title}</a></div>
              <div className="section">


                <div className="columns">

                    <div className="column is-2x" style={{padding: "0px", margin: "0px",backgroundColorx: "orange", align:"left"}}>
                    <Img fluid={featuredImgFluid}  style={{width: "350px", padding: "0px", margin: "0px", backgroundColorx: "orange", align:"left"}}/>
                    </div>

                    <div style={{textAlign: 'left', backgroundColorx: "#800000", positionx: "relative", top:"0px"}} className="column is-2x">
                    <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                      {title}
                    </h1>


                    <PostContent content={content} />
                    {tags && tags.length ? (
                      <div style={{ marginTop: `2rem` }}>
                        <h4>Tags</h4>
                        <ul className="taglist">
                          {tags.map(tag => (
                            <li key={tag + `tag`}>
                              <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : null}

                    </div>


                </div>

                <div>
                <h4 className="title is-size-3">Reviews</h4>
                <div>

    <Link className="btn" to={"/forms/comment?page_id="} style={{marginTop: "10px", paddingTop: "9px", width: "210px", paddingx: "10px", boxShadow: "none", backgroundColor: "#ff0000", color: "white"}}>
      Review
    </Link>
    <p><br></br></p>
    </div>

                {comments && comments.length ? (
                        <div style={{ marginTop: "0px" }}>


                              {comments.map(comment => (


                                <div style={{ marginTop: "20px" }}>

                                <div><b>{comment.member}</b></div>







                                <div>{comment.text}</div>
                                </div>
                              ))}

                          </div>
                        ) : null}










                </div>

              </div>






          </div>
        </div>
      </div>
    </section>
  )
}

RestaurantPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  featuredImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  helmet: PropTypes.object,
  comments: PropTypes.object,
  references: PropTypes.object,
}

const RestaurantPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout pageMetadata = {post.frontmatter.pageMetadata}>
      <RestaurantPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        featuredImage={post.frontmatter.featuredImage}
        comments={post.frontmatter.comments}
        tags={post.frontmatter.tags}
        references={post.frontmatter.references}
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

RestaurantPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default RestaurantPost

export const pageQuery = graphql`
  query RestaurantPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        pageMetadata {
          title
          description
        }
        date(formatString: "MMMM DD, YYYY")
        title
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 350, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tags
        comments{
          member
          text
          rating
          commentId
          parentCommentId
        }
        references{
          text
          link
        }
      }
    }
  }
`
